import axios from "axios";
import { Component } from "react";

class ApiContent extends Component {
  static get = async () => {
    const result = await axios({
      url: "content",
      method: "get",
    });
    return result;
  };
  static insert = async (data) => {
    const result = await axios({
      url: "content",
      method: "post",
      data: data,
    });
    return result;
  };
  static update = async (data) => {
    const result = await axios({
      url: "content",
      method: "put",
      data: data,
    });
    return result;
  };
  static delete = async (data) => {
    const result = await axios({
      url: `content/${data.id}`,
      method: "delete",
    });
    return result;
  };
}

export default ApiContent;
