import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Space, Modal, Row, Col, Select } from 'antd';
import { useHistory } from "react-router-dom";
import ApiUser from "../../Api/ApiUser";
import ApiMasterCountry from "../../Api/ApiMasterCountry";
import ApiMasterPlant from "../../Api/ApiMasterPlant";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../Utils/WindowSize";
const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 5,
  },
};

const Profile = () => {
    const history = useHistory();
    const userState = useSelector((state) => state.user);
    const formRef = useRef(null);
    const [form] = Form.useForm();
    const [width, height] = useWindowSize();

    const [countryDDL, setCountryDDL] = useState([]);
    const [rawPlantData, setRawPlantData] = useState([]);
    const [plantDDL, setPlantDDL] = useState([]);
    const { t, i18n } = useTranslation();
    const [defaultLanguage, setDefaultLanguage] = useState("");
    const [userData, setUserData] = useState("");
    const dispatch = useDispatch();

    const userRef = useRef(null);
    const [country, setCountry] = useState(1);
    const [user, setuser] = useState({});
    const [loadingSaveProfile, setloadingSaveProfile] = useState(false);
    const tailLayout = {
      wrapperCol: {
        offset: !(width >= 768) ? 6 : 10,
        span: 6,
      },
    };

    useEffect(() => {
        getLanguage();
        getDataCountry();
        getDataPlant();
      return () => {};
    }, []);

    useEffect(() => {
        getUserData();
      return () => {};
    }, [countryDDL, rawPlantData]);

    const getDataCountry = async () => {
      try {
        const result = await ApiMasterCountry.get();
        if (result.status === 200) {
          const { data } = result.data;
          for (let i = 0; i < data.length; i++) {
            data[i].order = i + 1;
          }
          setCountryDDL(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
  
    const getDataPlant = async () => {
      try {
        const result = await ApiMasterPlant.get();
        if (result.status === 200) {
          const { data } = result.data;
          setRawPlantData(data);
          for (let i = 0; i < data.length; i++) {
            data[i].order = i + 1;
          }
          setPlantDDL(data);
          console.log(data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const onChangeCountryDDL = (e) => {
      setCountry(e);
      if(e && countryDDL.length > 0 && rawPlantData.length > 0){
        console.log("e", e);
        var country = countryDDL.find((x) => x.id == e);
        console.log("country", country);
        var ctPlant = rawPlantData.filter((x) => x.country_code == country.country_code);
        console.log("ctPlant", ctPlant);
        for (let i = 0; i < ctPlant.length; i++) {
          ctPlant[i].order = i + 1;
        }
        setPlantDDL(ctPlant);
        form.setFieldsValue({
          plant: null,
        });
      }
    };

    const getLanguage = async () => {
      const language = await localStorage.getItem("language");
      if (language === null || language === "") {
        setDefaultLanguage("en");
      } else {
        i18n.changeLanguage(language);
        setDefaultLanguage(language);
      }
    };

    const getUserData = async () => {
      try {
        console.log("userState", userState);
        setUserData(userState);
        onChangeCountryDDL(userData.country_id);
        form.setFieldsValue({
          username: userData.username,
          password: "",
          userfullname: userData.userfullname,
          useremail: userData.useremail,
          usermobile: userData.usermobile,
          useridcard: userData.useridcard,
          country: userData.country_id,
          plant: userData.plant_id,
          company: userData.company,
        });
        // console.log("form", form);
        
      } catch (error) {
        console.log(error);
        Swal.fire(t('error'), error, 'warning').then((result) => {
          history.go(-1);
        });
      }
    };

    const onFinish = async (values) => {
      setuser(values);
      updateProfile(values);
    };

    const onSaveProfile = async () => {
      // settype("update");
      formRef.current.submit();
    };

    const updateProfile = async (values) => {
      console.log("value", values);
      setloadingSaveProfile(true);
      try {
        var model = {
          userid: userState.userid,
          username: values.username,
          password: values.password,
          userfullname: values.userfullname,
          useremail: values.useremail,
          useridcard: values.useridcard,
          usermobile: values.usermobile,
          company: values.company == undefined ? '' : values.company,
          country_id: country,
          plant_id: values.plant == undefined ? 0 : values.plant,
        };
        console.log(values.company)
        const result = await ApiUser.update(model);
        if (result.status === 200) {
          const data = result.data;

          dispatch({ type: 'set_user', user: data.user});

          history.push("/home");
          history.go(0);
        }
      } catch (error) {
        var errMsg = "";
        if(error.response.data.message){
          errMsg = error.response.data.message
        }
        else if(error.response.data){
          errMsg = error.response.data
        }
        else if(error.response){
          errMsg = error.response
        }
        else if(error){
          errMsg = error
        }
        setloadingSaveProfile(false);
        Modal.error({
          okText: t("close"),
          content: t(errMsg),
          onOk: () => {
            // onReset();
          },
        });
      }
    };

    return (
    <div>
        <div>
            <div className="summary-body">
            <Form
                  form={form}
                  {...layout}
                  // {...formItemLayout}
                  key={"formProfile"}
                  ref={formRef}
                  name="profile"
                  onFinish={onFinish}
                  initialValues={{
                    residence: ["zhejiang", "hangzhou", "xihu"],
                    prefix: "86",
                  }}
                >
                  <Form.Item
                    placeholder={t("username")}
                    name="username"
                    label={<label>{t("username")} <span style={{color: "red"}}>*</span></label>}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: t("username_require"),
                    //   },
                    // ]}
                  >
                    <Input ref={userRef} autoComplete="off" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    // rules={[
                    //   {
                    //     required: (!userState.is_adfs) || userState.is_adfs == 0,
                    //     message: t("password_require"),
                    //   },
                    // ]}
                    label={<label>{t("password")}</label>}
                    hidden={userState.is_adfs && userState.is_adfs == 1}
                  >
                    <Input autoComplete="off" type="password" disabled={userState.is_adfs && userState.is_adfs == 1} />
                  </Form.Item>
                  <Form.Item
                    name="userfullname"
                    rules={[
                      {
                        required: true,
                        message: t("userfullname_require"),
                      },
                    ]}
                    label={<label>{t("userfullname")} <span style={{color: "red"}}>*</span></label>}
                  >
                    <Input type="text" />
                  </Form.Item>
                  <Form.Item
                    name="useremail"
                    rules={[
                      {
                        type: "email",
                        message: t("emailvalidate"),
                      },
                      {
                        required: true,
                        message: t("useremail_require"),
                      },
                    ]}
                    label={<label>{t("useremail")} <span style={{color: "red"}}>*</span></label>}
                  >
                    <Input type="email" />
                  </Form.Item>
                  <Form.Item
                    name="usermobile"
                    rules={[
                      {
                        pattern: /^[0-9]{10}$/gm,
                        message: t("mobilevalidate"),
                      },
                      // {
                      //   required: true,
                      //   message: t("usermobile_require"),
                      // },
                    ]}
                    label={t("usermobile")}
                  >
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item
                    name="useridcard"
                    hidden={userState.is_adfs && userState.is_adfs == 1}
                    rules={[
                      {
                        pattern: /^[0-9]{13}$/gm,
                        message: t("idcardvalidate"),
                      },
                      // {
                      //   required: country == 1,
                      //   message: t("useridcard_require"),
                      // },
                    ]}
                    label={t("useridcard")}
                  >
                    <Input type="number" />
                  </Form.Item>

                  <Form.Item
                    name="country"
                    label={<label>{t("country")} <span style={{color: "red"}}>*</span></label>}
                    initialValue={1}
                    rules={[
                      {
                        required: true,
                        message: t("country_require"),
                      },
                    ]}
                  >
                    <Select
                      onChange={(e) => {
                        onChangeCountryDDL(e);
                      }}
                    >
                      {countryDDL.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item.id}>
                            {item.country_name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  {userState.is_employee == 1 ? (
                    <Form.Item
                      name="plant"
                      label={<label>{t("plant")} <span style={{color: "red"}}>*</span></label>}
                      rules={[
                        {
                          required: true,
                          message: "Please select Plant",
                        },
                      ]}
                    >
                      <Select>
                        {plantDDL.map((item, index) => {
                          return (
                            <Select.Option key={index} value={item.id}>
                              {item.plantname}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  ) : (
                    <></>
                  )}

                  {userState.is_employee == 0 ? (
                    <Form.Item
                      name="company"
                      rules={[
                        {
                          required: true,
                          message: t("company_require"),
                        },
                      ]}
                      label={t("company")}
                    >
                      <Input type="text" />
                    </Form.Item>
                  ) : (
                    <></>
                  )}

                  <Form.Item {...tailLayout}>
                    <Space>
                      <Button
                        loading={loadingSaveProfile}
                        onClick={onSaveProfile}
                        style={{ minWidth: width < 768 ? 130 : 130 }}
                        htmlType="button"
                        type="default"
                      >
                        {t("saveProfile")}
                      </Button>
                    </Space>
                  </Form.Item>
                  {/* </Space> */}
                </Form>
            </div>
        </div>
    </div>
    );
}
export default Profile;
