import axios from 'axios';
import { Component } from 'react';
import { setHeaderAuth } from '../Utils';

class ApiMasterPlant extends Component {
  static get = async (country) => {
      var url = `mt_plant`;
      if(country){
          url = `mt_plant/bc/${country}`
      }
      const result = await axios({
          url: url,
          method: "get",
      });
      return result;
  };
}

export default ApiMasterPlant;
