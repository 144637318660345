// Create the BlobServiceClient object which will be used to create a container client
const {
  BlobServiceClient,
  BlobSASPermissions,
} = require('@azure/storage-blob');
const { AZURE_STORAGE_CONNECTION_STRING } = require('../env');

const account = 'riskevaluationstoragedev';
const sas =
  '?sv=2020-04-08&st=2021-05-27T07%3A50%3A31Z&se=2021-05-28T07%3A50%3A31Z&sr=c&sp=rl&sig=b92tWDFr1tk%2F6xtC63osITGmATampJCB1Ue0Hx%2F0rew%3D';
const containerName = 'event';

const blobSasUrl =
  'https://riskevaluationstoragedev.blob.core.windows.net/event?sv=2020-04-08&st=2021-05-27T07%3A50%3A31Z&se=2021-05-28T07%3A50%3A31Z&sr=c&sp=rl&sig=b92tWDFr1tk%2F6xtC63osITGmATampJCB1Ue0Hx%2F0rew%3D';

// const blobServiceClient = new BlobServiceClient(
//   `https://riskevaluationstoragedev.blob.core.windows.net/?sv=2020-02-10&ss=b&srt=co&sp=rwdlactfx&se=2021-06-30T14:26:01Z&st=2021-05-27T06:26:01Z&spr=https&sig=6Ela0GICiIuAL9eCNUHqeZJgQWhQaLzui%2B2PXargmaw%3D`
// );

const blobServiceClient = new BlobServiceClient(blobSasUrl);

export const DownloadFile = async (containerName, blobName) => {
  //   const blobServiceClient = await BlobServiceClient.fromConnectionString(
  //     AZURE_STORAGE_CONNECTION_STRING
  //   );

  const containerClient = await blobServiceClient.getContainerClient(
    containerName
  );

  console.log(await containerClient.exists());

  //   if (!(await containerClient.exists())) {
  //     await containerClient.create();
  //   }
  //   const blobClient = await containerClient.getBlobClient(blobName);
  //   const downloadBlockBlobResponse = await blobClient.download();
  //   console.log('downloadBlockBlobResponse', downloadBlockBlobResponse);
  //   const downloaded = await blobToString(
  //     await downloadBlockBlobResponse.blobBody
  //   );
  //   console.log('Downloaded blob content', downloaded);

  //   // [Browsers only] A helper method used to convert a browser Blob into string.
  //   async function blobToString(blob) {
  //     const fileReader = new FileReader();
  //     return new Promise((resolve, reject) => {
  //       fileReader.onloadend = (ev) => {
  //         resolve(ev.target.result);
  //       };
  //       fileReader.onerror = reject;
  //       fileReader.readAsText(blob);
  //     });
  //   }
};
