// import Routers from './Routers'
import React, { Component } from 'react';
import './App.scss';
import Login from './pages/login';
import Register from './pages/register';
import Navbar from './Components/Navbar/Narbar';
import {
  BrowserRouter,
  HashRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Survey from './Components/survey';
const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
);
function App() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path='/'
            name='Login Page'
            render={(props) => <Login {...props} />}
          />
          <Route
            path='/login'
            name='Login Page'
            render={(props) => <Login {...props} />}
          />
          <Route
            path='/register'
            name='Register'
            render={(props) => <Register {...props} />}
          />

          <Route path='/home' name='Home' component={Navbar} />
          <Route path='/*' name='Home' component={Navbar} />
          {/* <Route exact path='/*' name='Home' component={Navbar} /> */}
          {/* <Route path='/survey' component={Survey} /> */}
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
