import axios from 'axios';
import { Component } from 'react';
import { setHeaderAuth } from '../Utils';

class ApiMasterCountry extends Component {
  static get = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: 'mt_country',
      method: 'get',
    });
    return result;
  };
}

export default ApiMasterCountry;
