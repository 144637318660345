import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ProgressBar from '../../Utils/Progress';
import './Home.scss';
import ApiTranEvent from '../../Api/ApiTranEvent';
import ApiTranSurvey from '../../Api/ApiTranSurvey';
import { Collapse } from 'antd';
import ApiHistory from '../../Api/ApiHistory';
import { ConvertDateShort } from '../../Utils/date';
import { DownloadFile } from '../../Utils/blob';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  const history = useHistory();
  const [tab1, setTab1] = React.useState(true);
  const [tab2, setTab2] = React.useState(false);
  const [eventData, setEventData] = useState([]);
  const [surveyData, setSurveyData] = useState([]);
  const [answerData, setAnswerData] = useState([]);
  const [eventDataHistory, seteventDataHistory] = useState([]);
  const [surveyDataHistory, setSurveyDataHistory] = useState([]);
  const { t, i18n } = useTranslation();
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const userState = useSelector((state) => state.user);

  const { Panel } = Collapse;

  useEffect(() => {
    getEventData();
    getSurveyData();
    getAllAnswerData();
    getLanguage();

    return () => {};
  }, []);

  useEffect(() => {
    generateEvent();
    return () => {};
  }, [eventData, surveyData, answerData, defaultLanguage]);

  const getLanguage = async () => {
    const language = await localStorage.getItem('language');
    if (language === null || language === '') {
      setDefaultLanguage('en');
    } else {
      i18n.changeLanguage(language);
      setDefaultLanguage(language);
    }
  };

  const getEventData = async () => {
    try {
      console.log("userState", userState);
      const result = await ApiTranEvent.get(userState.country_code);
      const history = await ApiHistory.get();

      if (result.status === 200) {
        var { data } = result.data;
        setEventData(data);
      }
      if (history.status === 200) {
        var { historys, surveys } = history.data;
        seteventDataHistory(historys);
        setSurveyDataHistory(surveys);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSurveyData = async () => {
    try {
      const result = await ApiTranSurvey.get();
      if (result.status === 200) {
        const { data } = result.data;
        setSurveyData(data);
      }
    } catch (error) {}
  };

  function callback(key) {
    console.log(key);
  }

  const getAllAnswerData = async () => {
    try {
      const result = await ApiTranSurvey.getAllAnswer(userState.country_code);
      if (result.status === 200) {
        const { data } = result.data;
        setAnswerData(data);
      }
    } catch (error) {}
  };

  function callback(key) {
    console.log(key);
  }

  const generateEvent = () => {
    return eventData
      .filter((x) => x.is_close == 0)
      .map((item, i) => {
        return (
          <EventListComponent
            eventData={item}
            surveyData={surveyData}
            history={history}
            t={t}
            answerData={answerData}
            userState={userState}
            defaultLanguage={defaultLanguage}
          />
        );
      });
  };

  const generateEventHistory = () => {
    return eventDataHistory.map((item, i) => {
      return (
        <EventListComponent
          eventData={item}
          surveyData={surveyDataHistory}
          history={history}
          type={'close'}
          tab={'history'}
          t={t}
          answerData={answerData}
          userState={userState}
          defaultLanguage={defaultLanguage}
        />
      );
    });
  };

  return (
    <div>
      <div className='table-card-container'>
        <div
          className='tab-item '
          onClick={() => {
            setTab1(true);
            setTab2(false);
          }}
          id={tab1 === true && 'active'}
        >
          <i className='fas fa-poll-h'></i>
          <span>{t("survey")}</span>
        </div>
        <div
          className='tab-item'
          onClick={() => {
            setTab1(false);
            setTab2(true);
          }}
          id={tab2 === true && 'active'}
        >
          <i className='fas fa-history'></i>
          <span>{t("surveyHistory")}</span>
        </div>
      </div>
      <div className='tab-body'>
        <div
          className='tab-body-items'
          id={tab1 === true && 'tab-body-items-active'}
        >
          {generateEvent()}
        </div>

        <div
          className='tab-body-items'
          id={tab2 === true && 'tab-body-items-active'}
        >
          {generateEventHistory()}
        </div>
      </div>
    </div>
  );
};

const EventListComponent = ({
  eventData,
  surveyData,
  history,
  type,
  tab = '',
  t,
  answerData,
  userState,
  defaultLanguage
}) => {
  var surveys = surveyData.filter((x) => x.eventid == eventData.id);
  var answers = answerData.filter((x) => x.createby == userState.userid);
  var waitList = false;
  for (let i = 0; i < surveys.length; i++) {
    if (surveys[i].is_reuse == 1) {
      waitList = true;
    }else{
      var ans = answers.filter((x) => x.survey_id == surveys[i].id);
      if(ans.length == 0){
        waitList = true;
      }    
    }
  }
  // if(eventData.id == 26 && tab != 'history'){
  //   console.log("tab::", tab);
  //   console.log("eventData::", eventData);
  //   console.log("surveyData::", surveyData);
  //   console.log("surveys::", surveys);
  //   console.log("userState::", userState);
  //   console.log("answerData::", answerData);
  // }
  const [isShowSurvey, setisShowSurvey] = useState(false);
  if (waitList == false && tab != 'history'){
    return (<></>);
  }else{
    return (
      <>
        <div className='card-tab'>
          <div className='card-tab-body'>
            <img src={eventData.image} />
            <div id='flex-body'>
              <label onClick={() => setisShowSurvey(!isShowSurvey)}>
                {eventData.event_name}
              </label>
              <p>{eventData.event_desc}</p>
            </div>
          </div>
          <div className='card-tab-footer'>
            <div className={type == 'close' ? 'card-progress-footer-full' : 'card-progress-footer'}>
              <i className='fas fa-calendar-alt'></i>
              <span>
              {' '}{t("eventPeriod")} :{' '}
                <span id='black-span'>
                  {' '}
                  {ConvertDateShort(eventData.period_from, 'DD MMM YY', defaultLanguage)} -{' '}
                  {ConvertDateShort(eventData.period_to, 'DD MMM YY', defaultLanguage)}
                </span>
              </span>
            </div>
            {type == 'close' ? (
              ""
            ) : (
              <div className='card-eye-footer event'>
                <i className='fas fa-eye'></i>
                <p>{eventData.cnt_ans}</p>
              </div>
            )}
          </div>
        </div>
        {isShowSurvey && (
          <SurveyListComponent
            eventData={eventData}
            surveyData={surveyData}
            history={history}
            tab={tab}
            t={t}
            answerData={answerData}
            userState={userState}
            defaultLanguage={defaultLanguage}
          />
        )}
      </>
    );
  }

};

const SurveyListComponent = ({ eventData, surveyData, history, tab = '', t, answerData ,userState, defaultLanguage }) => {
  // console.log('surveyData::', surveyData);
  var survey = surveyData.filter((x) => x.eventid == eventData.id);
  if (tab == "history"){
    survey = answerData.filter((x) => x.event_id == eventData.id && x.userfullname == userState.userfullname);
  }
  // console.log('survey::', survey);
  return survey.map((item) => {
    var answer = answerData.filter((x) => x.survey_id == item.id);
    if (tab == "history"){
      answer = answerData.filter((x) => x.survey_id == item.survey_id);
    }
    var answered = answer.some((x) => x.createby == userState.userid);
    // console.log('userState::', userState);
    // console.log('answer::', answer);
    // console.log("answered", answered);
    if(answered && tab != "history" && item.is_reuse != 1){
      return(
        <div></div>
      );
    }else{
      return (
        <div className='card-tab'>
          <div className='card-tab-body'>
            <div className={'card-tab-body-list'}>
              <div>
                <i className='fas fa-industry'></i>
                <span>
                  {t("plant")} :{' '}
                  <span
                    id='black-span'
                    className={tab == 'history' ? '' : 'pointer'}
                    onClick={() => {
                      history.push(
                        tab == 'history'
                          ? `/surveyHistory?id=${item.id}`
                          : `/survey?id=${item.id}`
                      );
                    }}
                  >
                    {item.plantname}{' '}
                  </span>
                </span>
              </div>
              <div>
                <i className='fas fa-calendar-alt'></i>
                <span>
                  {tab == 'history' ?
                    t("answerDate")
                  : t("surveyDate")
                  } :{' '}
                  <span id='black-span'>
                    {tab == 'history'?
                      ConvertDateShort(item.createdate, 'DD MMM YY HH:mm', defaultLanguage)
                    : `${ConvertDateShort(item.survey_from, 'DD MMM YY', defaultLanguage)} - ${ConvertDateShort(item.survey_to, 'DD MMM YY', defaultLanguage)}`
                    }
                  </span>
                </span>
              </div>
              <div>
                <i className='fas fa-edit'></i>
                <span>
                  {t("surveyAnswered")} :{' '}
                  <span id='black-span'>
                    {answer.length}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className='card-tab-footer'>
            {tab == 'history'?
              <>
                <div
                  className='card-eye-footer'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(`/summary?id=${item.id}`);
                  }}
                >
                  <i className='fas fa-eye'></i>
                  <p>{t("summary")}</p>
                </div>
                <div
                  className='card-eye-footer'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    history.push(`/surveyHistory?id=${item.id}`);
                  }}
                >
                  <i className='fas fa-list'></i>
                  <p>{t("surveyDetail")}</p>
                </div>
              </>
            : 
              <div
                className='card-eye-footer-full'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push(`/survey?id=${item.id}`);
                }}
              >
                <i className='fas fa-edit'></i>
                <p>{t("answerSurvey")}</p>
              </div>
            }
          </div>
        </div>
      );
    }
  });
};

export default Home;
