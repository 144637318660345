import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  BrowserRouter,
} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Home from '../Home/Home';
import summary from '../summary/Summary';
import Survey from '../survey/index';
import SurveyHistory from '../surveyHistory/index';
import Profile from '../Profile/Profile';
import Branner from './Branner';
import './Navbar.scss';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const Narbar = (props) => {
  const [clicked, setClicked] = React.useState(false);
  const history = useHistory();
  const pageData = useSelector((state) => state.page);
  const { t, i18n } = useTranslation();
  const userState = useSelector((state) => state.user);

  useEffect(() => {
    // console.log(history.location.pathname);
    if  (history.location.pathname != "/profile"){
      if ((!userState.country_id) || userState.country_id == null || userState.country_id == 0) {
        Swal.fire('Warning', t("pleaseUpdateCountry"), 'warning').then((result) => {
          history.push('/profile');
          history.go(0);
        });
      }
    }
    return () => {};
  }, []);
  
  return (
    <div className='bg'>
      <nav className='NavbarItems'>
        <h1
          className='navbar-logo'
          onClick={() => {
            history.push('/home');
          }}
        >
          SCG PACKAGING
        </h1>
        <div
          className='menu-icon'
          onClick={() => {
            setClicked(!clicked);
          }}
        >
          <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
        </div>
        <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
          <li>
            <label className='nav-links' onClick={() => {
              history.push("/home");
              history.go(0);
            }}>
              {t("homepage")}
            </label>
          </li>
          <li>
            <label className='nav-links' onClick={() => {
              history.push("/profile");
              history.go(0);
            }}>
              {t("profile")}
            </label>
          </li>
          <li>
            <label className='nav-links' onClick={() => history.push("/")}>
              {t("logout")}
            </label>
          </li>
        </ul>
      </nav>
      <div className='branner-container'><Branner /></div>
      <main>
        <BrowserRouter>
          <Switch>
            <Route path='/home' component={Home} />
            <Route path='/summary' component={summary} />
            <Route path='/survey' component={Survey} />
            <Route path='/surveyHistory' component={SurveyHistory} />
            <Route path='/profile' component={Profile} />
            {/* <Route path='/*' component={Home} /> */}
          </Switch>
        </BrowserRouter>
      </main>
    </div>
  );
};

export default Narbar;
