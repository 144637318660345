import moment from "moment";
import "moment/locale/th";
export const ConvertDateShort = (date, format, lang) => {
  if (lang == "th"){
    moment.locale("th");
    return moment(date).add(543, "year").format(format);
  }
  else if (lang == "en"){
    moment.locale("en");
    return moment(date).format(format);
  }else{
    moment.locale("en");
    return moment(date).format(format);
  }
};

