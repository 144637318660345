import axios from 'axios';
import { Component } from 'react';

class ApiTranEvent extends Component {
    static get = async (country) => {
        var url = `tran_event`;
        if(country){
            url = `tran_event/bc/${country}`
        }
        const result = await axios({
            url: url,
            // url: `tran_event/`,
            method: "get",
        });
        return result;
    };
    static getById = async (id) => {
        const result = await axios({
            url: `tran_event/${id}`,
            method: "get",
        });
        return result;
    };
    static create = async (data) => {
        
        const result = await axios({
            url: "tran_event",
            method: data.id > 0 ? "put" : "post",
            data: data
        });
        return result;
    }
    static delete = async (data) => {
        const result = await axios({
            url: `tran_event/${data.id}`,
            method: "delete",
        });
        return result;
    }
}

export default ApiTranEvent;