import axios from 'axios';
import { Component } from 'react';
import { setHeaderAuth } from '../Utils';

class ApiHistory extends Component {
  static get = async () => {
    await setHeaderAuth();
    const result = await axios({
      url: 'history',
      method: 'get',
    });
    return result;
  };
  static getById = async (id) => {
    const result = await axios({
      url: `tran_survey/answerById/${id}`,
      method: 'get',
    });
    return result;
  };
}

export default ApiHistory;
