import axios from 'axios';
import { Component } from 'react';

class ApiTranSurvey extends Component {
  static get = async () => {
    const result = await axios({
      url: 'tran_survey',
      method: 'get',
    });
    return result;
  };
  static getById = async (id) => {
    const result = await axios({
      url: `tran_survey/${id}`,
      method: 'get',
    });
    return result;
  };
  static getAnswer = async (id, user) => {
    const result = await axios({
      url: `tran_survey/answerById/${id}`,
      method: 'get',
    });
    return result;
  };
  static getAllAnswer = async (country) => {
    var url = `tran_survey/answer/all`;
    if(country){
        url = `tran_survey/answer/all/${country}`
    }
    const result = await axios({
      url: url,
      method: "get",
    });
    return result;
  };
  static getByEventId = async (id) => {
    const result = await axios({
      url: `tran_survey/by_event/${id}`,
      method: 'get',
    });
    return result;
  };
  static create = async (data) => {
    const result = await axios({
      url: 'tran_survey',
      method: data.id > 0 ? 'put' : 'post',
      data: data,
    });
    return result;
  };
  static createAnswer = async (data) => {
    const result = await axios({
      url: 'tran_survey/answer',
      method: 'post',
      data: data,
    });
    return result;
  };
}

export default ApiTranSurvey;
